import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  // Dashboard: Order
  TodayOrdersPage,
  CustomOrderPage,
  OrderDetailPage,
  ArchivedOrderPage,
  // Report
  DailyByDeliveryDatePage,
  DailyByCreatedDatePage,
  OccasionsPage,
  DeptAndProfitPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: Project
  ProjectListPage,
  ProjectCreatePage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  UserChangeRole,
  UserChangePass,
  UserProjectPermission,
  UserAddDepartmentPage,
  // Dashboard: Department
  DepartmentCreatePage,
  DepartmentListPage,
  DepartmentUserListPage,
  // Dashboard: Discount
  DiscountListPage,
  DiscountCreatePage,
  // Dashboard: Products - Product
  ProductListPage,
  PlantItemListPage,
  NonPlantItemListPage,
  ArrangementSizeListPage,
  FlowersPage,
  ColorPage,
  QuantityPage,
  // Dashboard: Products- Florist
  FloristListPage,
  // Dashboard: Products - AddOns
  AddonsListPage,
  AddOnsSizeListPage,
  AddOnsSizeCreatePage,
  // Dashboard: Products - Price
  PriceListPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  MaintenancePage,
  ComingSoonPage,
  PageLogout,
  Page500,
  Page403,
  Page404,
  //
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
  HomePage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'ecommerce', element: <GeneralEcommercePage /> },
        { path: 'analytics', element: <GeneralAnalyticsPage /> },
        { path: 'banking', element: <GeneralBankingPage /> },
        { path: 'booking', element: <GeneralBookingPage /> },
        { path: 'file', element: <GeneralFilePage /> },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order/custom" replace />, index: true },
            {
              path: 'custom',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['admin', 'super-user', 'ceo', 'department-agent', 'department-manager']}
                >
                  <CustomOrderPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <TodayOrdersPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'detail',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <OrderDetailPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'archived',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <ArchivedOrderPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'report',
          children: [
            { element: <Navigate to="/dashboard/report/delivery-date" replace />, index: true },
            {
              path: 'delivery-date',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['admin', 'super-user', 'ceo', 'department-agent', 'department-manager']}
                >
                  <DailyByDeliveryDatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'created-date',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['admin', 'super-user', 'ceo', 'department-agent', 'department-manager']}
                >
                  <DailyByCreatedDatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'profit',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['admin', 'super-user', 'ceo', 'department-agent', 'department-manager']}
                >
                  <DeptAndProfitPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'occasions',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['admin', 'super-user', 'ceo', 'department-agent', 'department-manager']}
                >
                  <OccasionsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShopPage /> },
            { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
            { path: 'list', element: <EcommerceProductListPage /> },
            { path: 'product/new', element: <EcommerceProductCreatePage /> },
            { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
            { path: 'checkout', element: <EcommerceCheckoutPage /> },
          ],
        },
        {
          path: 'project',
          children: [
            { element: <Navigate to="/dashboard/project/list" replace />, index: true },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <ProjectCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <ProjectListPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        // USER
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
            { path: 'cards', element: <UserCardsPage /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':userID/permission',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserProjectPermission />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':userID/department',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserAddDepartmentPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':userID/role',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserChangeRole />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':userID/change-pass',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserChangePass />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <UserEditPage />
                </RoleBasedGuard>
              ),
            },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        // DEPARTMENT
        {
          path: 'department',
          children: [
            { element: <Navigate to="/dashboard/department/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <DepartmentListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':departmentId/user_list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <DepartmentUserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <DepartmentCreatePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        // DİSCOUNT
        {
          path: 'discount',
          children: [
            { element: <Navigate to="/dashboard/discount/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <DiscountListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <DiscountCreatePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        // Products
        {
          path: 'product',
          children: [
            {
              element: <Navigate to="/dashboard/product/list" replace />,
              index: true,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <ProductListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'plant-item',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <PlantItemListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'non-plant-item',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <NonPlantItemListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'arrangement',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <ArrangementSizeListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'flowers',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <FlowersPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'colors',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <ColorPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'quantity',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <QuantityPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'florist',
          element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <FloristListPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'addons',
          children: [
            {
              element: <Navigate to="/dashboard/addons/list" replace />,
              index: true,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard hasContent roles={['admin']}>
                  <AddonsListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'size',
              children: [
                {
                  element: <Navigate to="/dashboard/addons/size/list" replace />,
                  index: true,
                },
                {
                  path: 'list',
                  element: (
                    <RoleBasedGuard hasContent roles={['admin']}>
                      <AddOnsSizeListPage />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <RoleBasedGuard hasContent roles={['admin']}>
                      <AddOnsSizeCreatePage />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'price',
          element: (
            <RoleBasedGuard hasContent roles={['admin']}>
              <PriceListPage />
            </RoleBasedGuard>
          ),
        },

        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceListPage /> },
            { path: ':id', element: <InvoiceDetailsPage /> },
            { path: ':id/edit', element: <InvoiceEditPage /> },
            { path: 'new', element: <InvoiceCreatePage /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPostsPage /> },
            { path: 'post/:title', element: <BlogPostPage /> },
            { path: 'new', element: <BlogNewPostPage /> },
          ],
        },
        { path: 'files-manager', element: <FileManagerPage /> },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <MailPage /> },
            { path: 'label/:customLabel/:mailId', element: <MailPage /> },
            { path: ':systemLabel', element: <MailPage /> },
            { path: ':systemLabel/:mailId', element: <MailPage /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <ChatPage />, index: true },
            { path: 'new', element: <ChatPage /> },
            { path: ':conversationKey', element: <ChatPage /> },
          ],
        },
        { path: 'calendar', element: <CalendarPage /> },
        { path: 'kanban', element: <KanbanPage /> },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },

    // Main Routes
    {
      element: <CompactLayout />,
      children: [
        { path: 'login', element: <LoginPage /> },
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: 'logout', element: <PageLogout /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },

    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        // Demo Components
        {
          path: 'components',
          children: [
            { element: <ComponentsOverviewPage />, index: true },
            {
              path: 'foundation',
              children: [
                { element: <Navigate to="/components/foundation/colors" replace />, index: true },
                { path: 'colors', element: <FoundationColorsPage /> },
                { path: 'typography', element: <FoundationTypographyPage /> },
                { path: 'shadows', element: <FoundationShadowsPage /> },
                { path: 'grid', element: <FoundationGridPage /> },
                { path: 'icons', element: <FoundationIconsPage /> },
              ],
            },
            {
              path: 'mui',
              children: [
                { element: <Navigate to="/components/mui/accordion" replace />, index: true },
                { path: 'accordion', element: <MUIAccordionPage /> },
                { path: 'alert', element: <MUIAlertPage /> },
                { path: 'autocomplete', element: <MUIAutocompletePage /> },
                { path: 'avatar', element: <MUIAvatarPage /> },
                { path: 'badge', element: <MUIBadgePage /> },
                { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
                { path: 'buttons', element: <MUIButtonsPage /> },
                { path: 'checkbox', element: <MUICheckboxPage /> },
                { path: 'chip', element: <MUIChipPage /> },
                { path: 'data-grid', element: <MUIDataGridPage /> },
                { path: 'dialog', element: <MUIDialogPage /> },
                { path: 'list', element: <MUIListPage /> },
                { path: 'menu', element: <MUIMenuPage /> },
                { path: 'pagination', element: <MUIPaginationPage /> },
                { path: 'pickers', element: <MUIPickersPage /> },
                { path: 'popover', element: <MUIPopoverPage /> },
                { path: 'progress', element: <MUIProgressPage /> },
                { path: 'radio-button', element: <MUIRadioButtonsPage /> },
                { path: 'rating', element: <MUIRatingPage /> },
                { path: 'slider', element: <MUISliderPage /> },
                { path: 'stepper', element: <MUIStepperPage /> },
                { path: 'switch', element: <MUISwitchPage /> },
                { path: 'table', element: <MUITablePage /> },
                { path: 'tabs', element: <MUITabsPage /> },
                { path: 'textfield', element: <MUITextFieldPage /> },
                { path: 'timeline', element: <MUITimelinePage /> },
                { path: 'tooltip', element: <MUITooltipPage /> },
                { path: 'transfer-list', element: <MUITransferListPage /> },
                { path: 'tree-view', element: <MUITreesViewPage /> },
              ],
            },
            {
              path: 'extra',
              children: [
                { element: <Navigate to="/components/extra/animate" replace />, index: true },
                { path: 'animate', element: <DemoAnimatePage /> },
                { path: 'carousel', element: <DemoCarouselsPage /> },
                { path: 'chart', element: <DemoChartsPage /> },
                { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
                { path: 'editor', element: <DemoEditorPage /> },
                { path: 'form-validation', element: <DemoFormValidationPage /> },
                { path: 'image', element: <DemoImagePage /> },
                { path: 'label', element: <DemoLabelPage /> },
                { path: 'lightbox', element: <DemoLightboxPage /> },
                { path: 'map', element: <DemoMapPage /> },
                { path: 'mega-menu', element: <DemoMegaMenuPage /> },
                { path: 'multi-language', element: <DemoMultiLanguagePage /> },
                { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
                { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
                { path: 'scroll', element: <DemoScrollbarPage /> },
                { path: 'snackbar', element: <DemoSnackbarPage /> },
                { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
                { path: 'upload', element: <DemoUploadPage /> },
                { path: 'markdown', element: <DemoMarkdownPage /> },
              ],
            },
          ],
        },
      ],
    },
  ]);
}
