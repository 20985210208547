import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// mui
import { Stack, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// Path page
import { PATH_PAGE, PATH_DASHBOARD } from '../../routes_/paths';
import { LOGIN_ROOT } from '../../config-global';
// Login service
import LoginService from '../../services/login';
import UserService from '../../services/user';
// slice
// import { setUser } from '../../redux/slices/user';

// ----------------------------------------------------------------------

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search).get('params');

  const { login, userInfo } = useAuthContext();

  const decryptParams = async (params_) => {
    // params şifre çözme
    const decrypted_params = await LoginService.getDecryptUrlParams(params_);
    const { accessToken, refreshToken } = decrypted_params;
    // şifresi çözülmüş access token  verify etme
    const verify_token = await LoginService.verifyToken(decrypted_params.accessToken);

    // verify token status 401 ise 403 sayfasına yönlendir
    if (verify_token.status === 401) {
      navigate(PATH_PAGE.page403);
    } else if (verify_token.status === 200) {
      // access token geçerli ise login metdonu çalıştır. access token, reflesh token ve user bilgilerini gönder
      await login(accessToken, refreshToken, verify_token.data);

      const getUserInfo = await UserService.getUserSelf(verify_token.data.sub);

      if (getUserInfo.status === 200) {
        // dispatch(setUser(getUserInfo.data));
        if (getUserInfo?.data?.profile !== null) {
          userInfo({
            first_name: getUserInfo?.data?.profile?.first_name,
            last_name: getUserInfo?.data?.profile?.last_name,
            gender: getUserInfo?.data?.profile?.gender,
            phone_number: getUserInfo?.data?.profile?.phone_number,
          });
        }

        if (getUserInfo?.data?.profile === null) {
          navigate(PATH_DASHBOARD.user.account);
        } else {
          navigate(PATH_DASHBOARD.general.app);
        }
      } else {
        navigate(PATH_PAGE.page500);
      }
    }
  };

  useEffect(() => {
    if (!params) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = LOGIN_ROOT;
    } else {
      decryptParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, navigate]);

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 5,
        position: 'relative',
        height: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4">Please wait, Login in progress...</Typography>
    </Stack>
  );
}
