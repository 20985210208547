// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const WEB_TITLE = 'FloraNet - CRM';
export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const WS_ESHOP_DOMAIN = process.env.REACT_APP_WS_ESHOP_DOMAIN;

export const LOGIN_ROOT = process.env.REACT_APP_LOGIN_ROOT;

export const WS_ESHOP_API = {
  apiKey: process.env.REACT_APP_WS_ESHOP_API_KEY,
};

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const ROLE = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'Regular',
    value: 'regular',
  },
  {
    name: 'Customer',
    value: 'customer',
  },
  {
    name: 'Department Agent',
    value: 'department-agent',
  },
  {
    name: 'Department Manager',
    value: 'department-manager',
  },
  {
    name: 'External Project',
    value: 'external-project',
  },
  {
    name: 'Internal Project',
    value: 'internal-project',
  },
  {
    name: 'Ceo',
    value: 'ceo',
  },
  {
    name: 'Admin',
    value: 'admin',
  },
  // {
  //   name: 'Super User',
  //   value: 'super-user',
  // },
];

export const STATE = [
  {
    state: 'AL',
    name: 'Alabama',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'AK',
    name: 'Alaska',
    timezone: 'AKST',
    tax: false,
  },
  {
    state: 'AZ',
    name: 'Arizona',
    timezone: 'MST',
    tax: true,
  },
  {
    state: 'AR',
    name: 'Arkansas',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'CA',
    name: 'California',
    timezone: 'PST',
    tax: true,
  },
  {
    state: 'CO',
    name: 'Colorado',
    timezone: 'MST',
    tax: true,
  },
  {
    state: 'CT',
    name: 'Connecticut',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'DE',
    name: 'Delaware',
    timezone: 'EST',
    tax: false,
  },
  {
    state: 'DC',
    name: 'District of Columbia',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'FL',
    name: 'Florida',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'GA',
    name: 'Georgia',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'GU',
    name: 'Guam',
    timezone: 'CHST',
    tax: true,
  },
  {
    state: 'HI',
    name: 'Hawaii',
    timezone: 'HST',
    tax: true,
  },
  {
    state: 'ID',
    name: 'Idaho',
    timezone: 'MST',
    tax: true,
  },
  {
    state: 'IL',
    name: 'Illinois',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'IN',
    name: 'Indiana',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'IA',
    name: 'Iowa',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'KS',
    name: 'Kansas',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'KY',
    name: 'Kentucky',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'LA',
    name: 'Louisiana',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'ME',
    name: 'Maine',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'MD',
    name: 'Maryland',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'MA',
    name: 'Massachusetts',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'MI',
    name: 'Michigan',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'MN',
    name: 'Minnesota',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'MS',
    name: 'Mississippi',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'MO',
    name: 'Missouri',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'MT',
    name: 'Montana',
    timezone: 'MST',
    tax: false,
  },
  {
    state: 'NE',
    name: 'Nebraska',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'NV',
    name: 'Nevada',
    timezone: 'PST',
    tax: true,
  },
  {
    state: 'NH',
    name: 'New Hampshire',
    timezone: 'EST',
    tax: false,
  },
  {
    state: 'NJ',
    name: 'New Jersey',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'NM',
    name: 'New Mexico',
    timezone: 'MST',
    tax: true,
  },
  {
    state: 'NY',
    name: 'New York',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'NC',
    name: 'North Carolina',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'ND',
    name: 'North Dakota',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'MP',
    name: 'Northern Mariana Islands',
    timezone: 'ChST',
    tax: true,
  },
  {
    state: 'OH',
    name: 'Ohio',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'OK',
    name: 'Oklahoma',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'OR',
    name: 'Oregon',
    timezone: 'PST',
    tax: false,
  },
  {
    state: 'PA',
    name: 'Pennsylvania',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'PR',
    name: 'Puerto Rico',
    timezone: 'AST',
    tax: true,
  },
  {
    state: 'RI',
    name: 'Rhode Island',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'SC',
    name: 'South Carolina',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'SD',
    name: 'South Dakota',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'TN',
    name: 'Tennessee',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'TX',
    name: 'Texas',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'USVI',
    name: 'U.S. Virgin Islands',
    timezone: 'AST',
    tax: true,
  },
  {
    state: 'UT',
    name: 'Utah',
    timezone: 'MST',
    tax: true,
  },
  {
    state: 'VT',
    name: 'Vermont',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'VA',
    name: 'Virginia',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'WA',
    name: 'Washington',
    timezone: 'PST',
    tax: true,
  },
  {
    state: 'WV',
    name: 'West Virginia',
    timezone: 'EST',
    tax: true,
  },
  {
    state: 'WI',
    name: 'Wisconsin',
    timezone: 'CST',
    tax: true,
  },
  {
    state: 'WY',
    name: 'Wyoming',
    timezone: 'MST',
    tax: true,
  },
];
