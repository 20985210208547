import axiosWithAtInstance from '../utils/axiosWithAt';
import handleApiError from '../utils/apiErrorMessage';

class UserService {
  // * Create User
  /**
   * @param {object} user object with email and password. 
   * {
  "email": "user1@mail.com",
  "password": "Password!123"
}
   */
  static createUser = async (user) => {
    try {
      const response = await axiosWithAtInstance.post('/users', user);
      return response;
    } catch (err) {
      if (typeof err?.response?.data?.error?.message === 'string') {
        return err?.response?.data?.error?.message;
      }
      if (Array.isArray(err?.response?.data?.error?.message)) {
        return err?.response?.data?.error?.message[0];
      }
      return err?.response?.data?.error?.message;
    }
  };

  // * User List
  /**
   * @param {object} user url params with order, page, take, project_domain, user_email.
   * https://ws-eshop-dev.emrisoft.com/users?order=DESC&page=1&take=10
   */
  static userList = async (params) => {
    try {
      const response = await axiosWithAtInstance.get(`/users?${params}`);
      return response.data;
    } catch (err) {
      if (typeof err?.response?.data?.error?.message === 'string') {
        return err?.response?.data?.error?.message;
      }
      if (Array.isArray(err?.response?.data?.error?.message)) {
        return err?.response?.data?.error?.message[0];
      }
      return err?.response?.data?.error?.message;
    }
  };

  // * get User
  /**
   * @param {id} id get user
   */
  static getUser = async (id) => {
    try {
      const response = await axiosWithAtInstance.get(`/users/${id}`);
      return response;
    } catch (err) {
      return err;
    }
  };

  // * get Self User
  /**
   * @param {id} id get self user -  sadece kendi user id bilgilerini alabilir.
   */
  static getUserSelf = async (id) => {
    try {
      const response = await axiosWithAtInstance.get(`/users/${id}/self`);
      return response;
    } catch (err) {
      return err;
    }
  };

  // * Add User Profile
  /**
   * @param {number} id - User Id
   * @param {object} userInfo - User Info 
   * {
  "first_name": "string",
  "last_name": "string",
  "gender": "male",
  "phone_number": "string"
}
   */
  static addUserProfile = async (id, userInfo) => {
    try {
      const response = await axiosWithAtInstance.post(`/users/${id}/profile`, userInfo);
      return response;
    } catch (err) {
      return err;
    }
  };

  // * Patch User Profile
  /**
   * @param {number} id - User Id
   * @param {object} userInfo - User Info 
   * {
  "first_name": "string",
  "last_name": "string",
  "gender": "male",
  "phone_number": "string"
}
   */
  static editUserProfile = async (id, userInfo) => {
    try {
      const response = await axiosWithAtInstance.patch(`/users/${id}/profile`, userInfo);
      return response;
    } catch (err) {
      return err;
    }
  };

  // * Patch Project Permission - User
  /**
   * @param {number} id - User Id
   * @param {object} project_domain_id_list - Project id
   * {
  {
  "project_domain_id_list": [
    0
  ]
}
   */
  static projectPermissionForUser = async (id, project_domain_id_list) => {
    try {
      const response = await axiosWithAtInstance.patch(
        `/users/${id}/add-access-permission-to-these-projects`,
        project_domain_id_list
      );
      return response;
    } catch (err) {
      return err;
    }
  };

  // * User - Change Role - Patch
  /**
   * @param {number} id - User Id
   * @param {object} role - role name
   * {
  "role": "regular"
}
  
   */
  static changeRoleForUser = async (id, role) => {
    try {
      const response = await axiosWithAtInstance.patch(`/users/${id}/update-role`, role);
      return response;
    } catch (err) {
      return err;
    }
  };

  // * User - Change Password - Patch
  /**
   * @param {number} id - User Id
   * @param {object} pass - password
      {
        "password": "Password!123"
      }
  
   */
  static changePassword = async (id, pass) => {
    try {
      const response = await axiosWithAtInstance.patch(`/users/${id}/update-password`, pass);
      return response;
    } catch (err) {
      return handleApiError(err);
    }
  };

  // * Patch User Disable
  /**
   * @param {number} id - User Id

   */
  static userDisabled = async (id) => {
    try {
      const response = await axiosWithAtInstance.patch(`/users/${id}/disable`);
      return response;
    } catch (err) {
      return err;
    }
  };

  // * Patch User Enable
  /**
   * @param {number} id - User Id

   */
  static userEnabled = async (id) => {
    try {
      const response = await axiosWithAtInstance.patch(`/users/${id}/enable`);
      return response;
    } catch (err) {
      return err;
    }
  };
}

export default UserService;
