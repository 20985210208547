import PropTypes from 'prop-types';
// react-redux
import { useDispatch } from 'react-redux';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axiosWithAtInstance from '../utils/axiosWithAt';
import localStorageAvailable from '../utils/localStorageAvailable';
// redux slice
import { setUser } from '../redux/slices/user';
//
import { isValidToken, setSessionAt, setSessionRt, jwtDecode } from './utils';
// service
import UserService from '../services/user';
import LoginService from '../services/login';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'SET_USER') {
    return {
      ...state,
      user: {
        ...state.user,
        profile: action.payload.profile,
      },
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      isInitialized: false,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reactDispatch = useDispatch();

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        axiosWithAtInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        const user_at = jwtDecode(accessToken);
        // şifresi çözülmüş access token  verify etme
        const verify_token = await LoginService.verifyToken(accessToken);
        const user = await UserService.getUserSelf(user_at.sub);
        // reactDispatch(setUser(user.data));
        // userInfo({
        //   first_name: user?.data?.profile?.first_name,
        //   last_name: user?.data?.profile?.last_name,
        //   gender: user?.data?.profile?.gender,
        //   phone_number: user?.data?.profile?.phone_number,
        // });
        setSessionAt(accessToken);
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: verify_token.data,
          },
        });
        userInfo({
          first_name: user?.data?.profile?.first_name,
          last_name: user?.data?.profile?.last_name,
          gender: user?.data?.profile?.gender,
          phone_number: user?.data?.profile?.phone_number,
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (accessToken, refreshToken, user) => {
    setSessionAt(accessToken);
    setSessionRt(refreshToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // SET USER
  const userInfo = useCallback(async (profile) => {
    dispatch({
      type: 'SET_USER',
      payload: {
        profile,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axiosWithAtInstance.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSessionAt(null);
    setSessionRt(null);
    localStorage.removeItem('persist:user');
    dispatch({
      type: 'LOGOUT',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // IDLE LOGOUT
  const idleLogout = useCallback(() => {
    setSessionAt(null);
    setSessionRt(null);
    localStorage.removeItem('persist:user');
    dispatch({
      type: 'LOGOUT',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      idleLogout,
      userInfo,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register, userInfo, idleLogout]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
