import axios from 'axios';
// config
import { WS_ESHOP_DOMAIN, WS_ESHOP_API } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: WS_ESHOP_DOMAIN });

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `ApiKey ${WS_ESHOP_API.apiKey}`;
  return config;
});

export default axiosInstance;
