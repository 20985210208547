import axios from '../utils/axios';

class LoginService {
  static getDecryptUrlParams = async (params) => {
    try {
      const response = await axios.get(
        `standalone-service-util/decrypt-this-url-safe-data-as-json-object`,
        { params: { decoded_string: params } }
      );
      return response.data;
    } catch (err) {
      throw err.message;
    }
  };

  static verifyToken = async (apiKey) => {
    try {
      const response = await axios.post(`/authentication/verify-token`, {
        accessOrRefreshToken: apiKey,
      });
      return response;
    } catch (err) {
      return err.response;
    }
  };

  static getRefreshToken = async (refresh_token) => {
    try {
      const response = await axios.post(`/authentication/refresh-tokens`, {
        refreshToken: refresh_token,
      });
      return response;
    } catch (err) {
      return err.response;
    }
  };
}

export default LoginService;
