// routes
import { LOGIN_ROOT } from '../config-global';
// utils
import axios from '../utils/axiosWithAt';
// Login Service
import LoginService from '../services/login';

// ----------------------------------------------------------------------
export const jwtDecode = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      // refresh token yok. login sayfasına yönlendirilecek.
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = LOGIN_ROOT;
    }
    getRefreshToken(refreshToken);
  }, timeLeft);
};

// ----------------------------------------------------------------------
// Access Token add Session
export const setSessionAt = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
// ----------------------------------------------------------------------
// refresh Token add Session
export const setSessionRt = (refreshToken) => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

// ----------------------------------------------------------------------
// get refresh Token
const getRefreshToken = async (refresh_token) => {
  const reReceivedRefreshToken = await LoginService.getRefreshToken(refresh_token);

  if (reReceivedRefreshToken.status === 401) {
    // refresh token gecersiz. login sayfasına yönlendirilecek.
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = LOGIN_ROOT;
  } else if (reReceivedRefreshToken.status === 200) {
    const { accessToken, refreshToken } = reReceivedRefreshToken.data;
    setSessionAt(accessToken);
    setSessionRt(refreshToken);
  }
};
