// routes
import { PATH_DASHBOARD } from '../../../routes_/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  price: icon('ic_price'),
  store: icon('ic_store'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  addons: icon('ic_addons'),
  profit: icon('ic_profit'),
  project: icon('ic_project'),
  flower: icon('ic_flower'),
  search: icon('ic_search'),
  reports: icon('ic_reports'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  occasions: icon('ic_occasions'),
  calendar: icon('ic_calendar'),
  discount: icon('ic_discount'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  department: icon('ic_department'),
  today_orders: icon('ic_today_orders'),
};

const navConfig = [
  // ORDER
  // ----------------------------------------------------------------------
  {
    subheader: 'order',
    permission: ['department-agent', 'department-manager', 'admin', 'super-user', 'ceo'],
    items: [
      {
        title: 'todays orders',
        path: PATH_DASHBOARD.order.todayOrders,
        icon: ICONS.today_orders,
        permission: ['admin'],
      },
      {
        title: 'custom order',
        path: PATH_DASHBOARD.order.custom,
        icon: ICONS.ecommerce,
        permission: ['admin', 'super-user', 'ceo', 'department-agent', 'department-manager'],
      },
      {
        title: 'archived orders',
        path: PATH_DASHBOARD.order.archived,
        icon: ICONS.search,
        permission: ['admin'],
      },
    ],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   permission: ['regular', 'admin', 'super-user'],
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //       permission: ['regular', 'admin', 'super-user'],
  //     },
  //     // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //     // { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
  //   ],
  // },

  // REPORTS
  {
    subheader: 'Reports',
    permission: ['admin', 'super-user'],
    items: [
      // PROJECT
      {
        title: 'Daily',
        path: PATH_DASHBOARD.reports.dailyByDelivery,
        icon: ICONS.reports,
        permission: ['admin', 'super-user'],
        children: [
          { title: 'Delivery Date', path: PATH_DASHBOARD.reports.dailyByDelivery, permission: ['admin', 'super-user'] },
          { title: 'Creation Date', path: PATH_DASHBOARD.reports.dailyByCreate, permission: ['admin', 'super-user'] },
        ],
      },
      {
        title: 'Profit',
        path: PATH_DASHBOARD.reports.profit,
        icon: ICONS.profit,
        permission: ['admin'],
      },
      {
        title: 'Occasion',
        path: PATH_DASHBOARD.reports.occasions,
        icon: ICONS.occasions,
        permission: ['admin'],
      },
    ],
  },

  // ADMIN MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Administrator',
    permission: ['admin', 'super-user'],
    items: [
      // PROJECT
      {
        title: 'project',
        path: PATH_DASHBOARD.project.root,
        icon: ICONS.project,
        permission: ['admin', 'super-user'],
        children: [
          { title: 'list', path: PATH_DASHBOARD.project.list, permission: ['admin', 'super-user'] },
          {
            title: 'create',
            path: PATH_DASHBOARD.project.new,
            permission: ['admin', 'super-user'],
          },
        ],
      },

      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        permission: ['super-user', 'admin'],
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list, permission: ['admin', 'super-user'] },
          { title: 'create', path: PATH_DASHBOARD.user.new, permission: ['admin', 'super-user'] },
          //  { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
          {
            title: 'account',
            path: PATH_DASHBOARD.user.account,
            permission: ['super-user', 'admin'],
          },
        ],
      },

      // DEPARTMENT
      {
        title: 'department',
        path: PATH_DASHBOARD.department.root,
        icon: ICONS.department,
        permission: ['admin', 'super-user'],
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.department.list,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'create',
            path: PATH_DASHBOARD.department.new,
            permission: ['admin', 'super-user'],
          },
        ],
      },

      // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
      // {
      //   title: 'File manager',
      //   path: PATH_DASHBOARD.fileManager,
      //   icon: ICONS.folder,
      // },
    ],
  },

  // MARKETING MANAGEMENT
  {
    subheader: 'Marketing',
    permission: ['admin', 'super-user'],
    items: [
      {
        title: 'Discount',
        path: PATH_DASHBOARD.discount.root,
        icon: ICONS.discount,
        permission: ['admin', 'super-user'],
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.discount.list,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'create',
            path: PATH_DASHBOARD.discount.new,
            permission: ['admin', 'super-user'],
          },
        ],
      },
    ],
  },

  // PRODUCTS MANAGEMENT
  {
    subheader: 'Products',
    permission: ['admin', 'super-user'],
    items: [
      {
        title: 'Products',
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.flower,
        permission: ['admin', 'super-user'],
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.products.list,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'plant item',
            path: PATH_DASHBOARD.products.plantItem,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'non plant item',
            path: PATH_DASHBOARD.products.nonPlantItem,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'Arrangement Sizes',
            path: PATH_DASHBOARD.products.arrangement,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'Flowers',
            path: PATH_DASHBOARD.products.flowers,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'Colors',
            path: PATH_DASHBOARD.products.colors,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'Quantity',
            path: PATH_DASHBOARD.products.quantity,
            permission: ['admin', 'super-user'],
          },
        ],
      },
      {
        title: 'Add-Ons',
        path: PATH_DASHBOARD.addons.root,
        icon: ICONS.addons,
        permission: ['admin', 'super-user'],
        children: [
          {
            title: 'list',
            path: PATH_DASHBOARD.addons.list,
            permission: ['admin', 'super-user'],
          },
          {
            title: 'Sizes',
            path: PATH_DASHBOARD.addons.size,
            permission: ['admin', 'super-user'],
          },
        ],
      },
      {
        title: 'Price',
        path: PATH_DASHBOARD.price.root,
        icon: ICONS.price,
        permission: ['admin', 'super-user'],
      },
    ],
  },
  {
    subheader: 'Services',
    permission: ['admin', 'super-user'],
    items: [
      {
        title: 'Florist',
        path: PATH_DASHBOARD.florist.root,
        icon: ICONS.store,
        permission: ['admin', 'super-user'],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   permission: ['regular', 'admin', 'super-user'],
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //       permission: ['regular', 'admin', 'super-user'],
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //       permission: ['regular', 'admin', 'super-user'],
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //       permission: ['regular', 'admin', 'super-user'],
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //       permission: ['regular', 'admin', 'super-user'],
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default navConfig;
