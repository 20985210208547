const handleApiError = (err) => {
  const errorMessage = err?.response?.data?.error?.message;

  if (typeof errorMessage === 'string') {
    return errorMessage;
  }

  if (Array.isArray(errorMessage)) {
    return errorMessage[0];
  }

  return errorMessage || err?.response?.data?.error;
};

export default handleApiError;
