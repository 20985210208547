import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// redux
import { useSelector } from 'react-redux';
import { useAuthContext } from '../../../auth/useAuthContext';

// locales
import { useLocales } from '../../../locales';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();
  const { user } = useAuthContext();

  return (
    <Stack sx={sx} {...other}>
      {data
        .filter((i) => i.permission.includes(user.role))
        .map((group) => {
          const key = group.subheader || group.items[0].title;

          return (
            <List key={key} disablePadding sx={{ px: 2 }}>
              {group.subheader && (
                <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
              )}

              {group.items
                .filter((i) => i.permission.includes(user.role))
                .map((list) => (
                  <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={1}
                    hasChild={!!list.children}
                    userRole={user.role}
                  />
                ))}
            </List>
          );
        })}
    </Stack>
  );
}
