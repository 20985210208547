// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  pageLogout: '/logout',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  order: {
    todayOrders: path(ROOTS_DASHBOARD, '/order/list'),
    custom: path(ROOTS_DASHBOARD, '/order/custom'),
    detail: path(ROOTS_DASHBOARD, '/order/detail'),
    archived: path(ROOTS_DASHBOARD, '/order/archived'),
  },
  reports: {
    dailyByDelivery: path(ROOTS_DASHBOARD, '/report/delivery-date'),
    dailyByCreate: path(ROOTS_DASHBOARD, '/report/created-date'),
    occasions: path(ROOTS_DASHBOARD, '/report/occasions'),
    profit: path(ROOTS_DASHBOARD, '/report/profit'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  project: {
    root: path(ROOTS_DASHBOARD, '/project'),
    new: path(ROOTS_DASHBOARD, '/project/new'),
    list: path(ROOTS_DASHBOARD, '/project/list'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    permission: (userId) => path(ROOTS_DASHBOARD, `/user/${userId}/permission`),
    addDepartment: (userId) => path(ROOTS_DASHBOARD, `/user/${userId}/department`),
    changeRole: (userId) => path(ROOTS_DASHBOARD, `/user/${userId}/role`),
    changePass: (userId) => path(ROOTS_DASHBOARD, `/user/${userId}/change-pass`),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  department: {
    root: path(ROOTS_DASHBOARD, '/department'),
    list: path(ROOTS_DASHBOARD, '/department/list'),
    userList: (departmentId) => path(ROOTS_DASHBOARD, `/department/${departmentId}/user_list`),
    new: path(ROOTS_DASHBOARD, '/department/new'),
  },
  discount: {
    root: path(ROOTS_DASHBOARD, '/discount'),
    list: path(ROOTS_DASHBOARD, '/discount/list'),
    new: path(ROOTS_DASHBOARD, '/discount/new'),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    plantItem: path(ROOTS_DASHBOARD, '/product/plant-item'),
    nonPlantItem: path(ROOTS_DASHBOARD, '/product/non-plant-item'),
    arrangement: path(ROOTS_DASHBOARD, '/product/arrangement'),
    flowers: path(ROOTS_DASHBOARD, '/product/flowers'),
    colors: path(ROOTS_DASHBOARD, '/product/colors'),
    quantity: path(ROOTS_DASHBOARD, '/product/quantity'),
  },
  florist: {
    root: path(ROOTS_DASHBOARD, '/florist'),
  },
  addons: {
    root: path(ROOTS_DASHBOARD, '/addons'),
    list: path(ROOTS_DASHBOARD, '/addons/list'),
    new: path(ROOTS_DASHBOARD, '/addons/new'),
    size: path(ROOTS_DASHBOARD, '/addons/size/list'),
    newSize: path(ROOTS_DASHBOARD, '/addons/size/new'),
  },
  price: {
    root: path(ROOTS_DASHBOARD, '/price'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
