import ReactDOM from 'react-dom/client';
//
import App from './App';
// ----------------------------------------------------------------------

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=en&callback=initMap`;
script.async = true;
document.body.appendChild(script);
