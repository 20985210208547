import { createSlice } from '@reduxjs/toolkit';
import { actions } from './kanban';

const initialState = {
  user: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUserProfile: (state, action) => {
      state.user = {
        ...state.user,
        profile: action.payload.profile, // Sadece profile kısmını güncelle
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUser, updateUserProfile } = slice.actions;
